<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <!-- <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
              </el-form-item> -->
              <el-form-item label="类型">
                <el-select v-model="searchForm.type" style="width: 140px" size="small" @change="search">
                  <el-option v-for="item in typeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="标题/内容" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" ref="table" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column type="index" label="编号"></el-table-column>
          <el-table-column prop="createTime" width="180" label="时间">
            <template slot-scope="scope">{{ scope.row.createTime | timefilters }}</template>
          </el-table-column>
          <el-table-column prop="title" label="标题" width="270">
            <template slot-scope="scope">{{ scope.row.typeName }}<span style="margin: 0 10px">|</span>【{{ scope.row.title }}】</template>
          </el-table-column>
          <el-table-column prop="content" label="内容">
            <template slot-scope="scope">
              <span style="margin-right: 10px; color: #777">{{ scope.row.content }}</span>
              <el-link type="primary" v-if="scope.row.subType == 1" @click="openPage('/Outpatient/ChargedList', scope.row)">开始备药</el-link>
              <el-link type="primary" v-if="scope.row.subType == 2" @click="openPage('/Outpatient/RefundReviewList', scope.row)">退费审核</el-link>
              <el-link type="primary" v-if="scope.row.subType == 3" @click="openPage('/Outpatient/InventoryAlert', scope.row)">查看</el-link>
              <el-link type="primary" v-if="scope.row.subType == 4" @click="openPage('/Outpatient/ExpireAlert', scope.row)">查看</el-link>
              <el-link type="primary" v-if="scope.row.atta.length > 0" @click="toogleExpand(scope.row)">查看附件</el-link>
            </template>
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-row>
                <el-col :span="23" :offset="1">
                  <div class="info-item" v-if="scope.row.atta.length > 0">
                    <template v-for="item in scope.row.atta">
                      <el-image hide-on-click-modal :src="Services.Authorization + item.filePath" preview-teleported :zoom-rate="1.2" :preview-src-list="[Services.Authorization + item.filePath]"></el-image>
                    </template>
                  </div>
                  <el-empty :image-size="50" description="无附件" v-else/>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Msg } from '../../../components/HospitalDomain/Msg'
export default {
  name: '',
  components: {
    bread,
  },
  data() {
    var msg = new Msg(this.TokenClient, this.Services.Authorization)
    return {
      MsgDomain: msg,
      tableData: [],
      searchForm: {
        keyWord: '',
        type: -1,
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      typeData: [
        {
          id: -1,
          name: '全部',
        },
        {
          id: 0,
          name: '系统消息',
        },
        {
          id: 2,
          name: '快递消息',
        },
        {
          id: 3,
          name: '支付消息 ',
        },
        {
          id: 4,
          name: '账号消息',
        },
        {
          id: 5,
          name: '申请消息',
        },
        {
          id: 6,
          name: '门诊消息',
        },
        {
          id: 7,
          name: '转诊消息',
        },
        {
          id: 8,
          name: '升级续费',
        },
      ],
    }
  },
  mounted() {
    this.GetList()
    if (this.ClientId != this.YunClient) {
      let types = this.typeData.filter((i) => i.id != 6)
      this.typeData = types
    }
  },
  methods: {
    openPage(path, item) {
      let query = {}
      let orderNum = ''
      //  消息类型 type ： 0 系统消息    2 快递消息  3 支付消息 （支出和入账）4 账号消息5 申请消息6 门诊消息 7 转诊消息 8 升级续费消息
      //  子类型 SubType：
      //  机构后台门诊消息  1付款成功开始备药  2退款申请通知      3库存预警消息     4效期预警消息
      //  总后台 5机构提现申请消息  6机构升级版本申请  7机构申请入驻   8店铺开通申请 9商品审核申请  10平台介入申请 11 议价申请消息
      //  医生端/app/小程序 门诊消息   12审方医生消息    13  审核成功通知  14 审核拒绝通知  15退款拒绝通知
      //  医生端/app/小程序 账号/会员消息  16会员即将到期  17会员已到期
      //  代理商后台升级续费消息  18续费升级申请  19对公审批完成  20 议价审批通知
      //  代理商后台账号消息  21机构审核申请 22店铺开通申请
      switch (item.subType) {
        case 1:
          //6 门诊消息--- 1付款成功开始备药  2退款申请通知
          // orderNum = item.content.split('MZ')[1].split('已付款成功')[0]
          // query = { orderNum: `MZ${orderNum}` }
          break
        case 2:
          // orderNum = item.content.split('MZ')[1].split('申请退款')[0]
          // query = { orderNum: `MZ${orderNum}` }
          break
      }
      this.$router.push({
        path: path,
        query: query,
      })
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    search() {
      this.PageIndex = 1
      this.GetList()
    },
    GetList() {
      var _this = this
      var item = _this.searchForm
      _this.MsgDomain.GetMessageList(
        1,
        item.type,
        _this.PageIndex,
        item.keyWord,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    toogleExpand(row) {
      let $table = this.$refs.table
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false)
        }
      })
      $table.toggleRowExpansion(row)
    },
  },
}
</script>
<style scoped>
.info-item .el-image {
  width: 80px;
  height: 50px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
